import React from 'react';
import { Image } from 'antd';
import logo from '../Assets/splash.png';
import { ThreeDots } from 'react-loader-spinner';

const CustomLazyLoading = () => {
    return (
        <div style={{ height: '100vh',  display:"flex",justifyContent:"center",alignItems:"center"}}>
            <div>
                
                <div  className='mx-4'>
                
                <img preview={false} src={logo} style={{width:"100%"}} />
                </div>
            </div>
        </div>
    )
}

export default CustomLazyLoading