import { Suspense, lazy, useEffect } from "react";
import { Helmet } from "react-helmet"
import ErrorBoundary from "./ErrorBoundary";
import { persistor, store } from "./store";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import Toast from "./Constant/Toast";
import CustomLazyLoading from "./Components/CustomLazyLoading";

function App() {
  const AppRouter = lazy(() => import("./Routes"));
  /* Handling Net Status & Visible Title */
  useEffect(() => {
    const handleOnline = () => {
      Toast("s", "Internet connection restored!", "top-center");
    };

    const handleOffline = () => {
      Toast("w", "Internet connection lost!", "top-center");
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rallii App ADMIN</title>
        <link rel="canonical" href="https://master.d2iqe5oyu6c58x.amplifyapp.com/static/media/logo.c59cbdc6edd7284f3c1f.png" /> 
        <meta property="og:image" content="https://master.d2iqe5oyu6c58x.amplifyapp.com/static/media/logo.c59cbdc6edd7284f3c1f.png" /> 
        <meta name="description" content="Rallii App" />
      </Helmet>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Suspense fallback={<CustomLazyLoading />}>
            <ErrorBoundary>
              <AppRouter />
            </ErrorBoundary>
          </Suspense>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
